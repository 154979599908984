.app__gallery {
  flex: 1;
  width: 100%;
  flex-direction: column;
}
.gallery__container {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 600px) {
    padding: 0;
    padding-top: 20px;
  }
}

.app__profile-text {
  // padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .p-text {
    font-size: 20px;
  }
}
.bold-text {
  font-size: 30px;
}
