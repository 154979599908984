.app__virtual-galleries {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}

#virtual-galleries {
  background: url('../../assets/gallery_bg.png') !important;
  box-shadow: 0px 0px 0px 4000px rgba(27, 61, 88, 0.5) inset;
  // move the image over to the right
  background-position: right;
  background-size: cover;
}

.virtual-galleries-container {
  width: auto;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
}

.virtual-galleries-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.virtual-galleries-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease-in-out;

  .icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .gallery-button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border-radius: 10px;
    background-color: var(--secondary-color);
    color: var(--white-color);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #18218b;
    }

    @media screen and (max-width: 450px) {
      padding: 0.75rem 1.5rem;
      font-size: 1.25rem;
    }
  }
}

.virtual-galleries-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}

.virtual-p {
  font-size: 20px !important;
  color: #e4dfdf !important;
}
