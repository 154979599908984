.money__tools-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.money__tools {
  flex: 1;
  margin-right: 20px;
  text-align: center;
}

.money__tools-item {
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .money__tools-wrapper {
    flex-direction: column;
  }

  .money__tools {
    flex: none;
    margin-right: 0;
    margin-bottom: 20px; /* Adjust margin as needed */
  }
}
.money__paragraph {
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
  line-height: 1.5;
  color: var(--gray-color);
}
.money__bold_text {
  font-size: 30px;
  color: var(--black-color);
  text-decoration: none;
  font-weight: 800;
  text-align: center;
}

.prime__capital {
  text-align: center;
  padding-top: 40px;
}
