.shop__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .header-shop-now-btn {
    margin: 20px 0;

    img {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }

  .p-text {
    font-size: 16px;
    text-align: center;
  }
}

// Media queries for responsiveness

@media (min-width: 600px) {
  .shop__container {
    flex-direction: row;
    justify-content: space-around;

    .header-shop-now-btn {
      margin: 20px;
      width: 45%;

      img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 900px) {
  .shop__container {
    padding: 40px;

    .header-shop-now-btn {
      margin: 30px;
      width: 30%;

      img {
        width: 100%;
      }
    }

    .p-text {
      font-size: 18px;
    }
  }
}

.shop__container.app__flex {
  flex-direction: column;
}

.sub-heading-shop {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
}
