.globe-row {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.globe-heading {
  text-align: center;
}

.globe-link {
  color: #007bff;
  text-decoration: none;
}

.globe-column {
  padding: 0 12px;
  text-align: center;
  flex-basis: 100%;
  max-width: 100%;

  @media only screen and (min-width: 640px) {
    flex-basis: 50%;
    max-width: 50%;
  }

  @media only screen and (min-width: 1024px) {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }

  @media only screen and (min-width: 1280px) {
    flex-basis: 16.66%;
    max-width: 16.66%;
  }
}

.globe-column h1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.globe-column ul {
  list-style: none;
  padding: 0;
}

.globe-column li {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}
