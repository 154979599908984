.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 100%;
  display: flex;
  flex-direction: row; /* Change to row layout */
  margin: 2rem;

  img {
    width: 418px; /* Set a fixed width for the image */
    height: 312px; /* Set a fixed height for the image */
    border-radius: 15px;
    object-fit: cover;
    margin-right: 20px; /* Add some space between the image and text */
  }

  .app__profile-text {
    // padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .p-text {
      font-size: 20px;
    }
  }
  .bold-text {
    font-size: 30px;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
    font-size: 30px;

    img {
      width: 150px;
      height: 150px;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    img {
      width: 100%;
      height: 400px;
      padding-bottom: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 100%;
      height: 400px;
      padding-bottom: 30px;
    }
  }
}
