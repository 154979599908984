.app__xmas {
  display: flex !important;
  align-items: flex-start !important;
}

.head-text.xmas {
  text-align: left;
}

.sub-heading-xmas {
  font-size: 28px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.info_container {
  padding-left: 20px;
  padding-right: 20px;
}

.p-text.xmas {
  font-size: 18px;
  margin-top: 10px;
}

.xmas__btn {
  padding: 0.75rem 1.5rem;
  background-color: var(--secondary-color);
  color: var(--white-color);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.xmas_btn_wrapper {
  padding-top: 40px;
}

@media screen and (min-width: 769px) {
  .xmas__container {
    width: 75%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .xmas_images img {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 15px;
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .app__xmas {
    align-items: center !important; /* Center items on smaller screens */
    flex-direction: column;
  }

  .head-text.xmas {
    text-align: center;
    font-size: 32px;
  }

  .sub-heading-xmas {
    font-size: 24px; /* Smaller font size for mobile screens */
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .info_container {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  .p-text.xmas {
    font-size: 16px; /* Reduce font size for better readability on mobile */
  }

  .xmas__btn {
    padding: 0.5rem 1rem; /* Adjust button padding for smaller screens */
    font-size: 0.875rem;
  }

  .xmas_btn_wrapper {
    padding-top: 20px;
  }

  .xmas__container {
    width: 100%; /* Full width for the image container on smaller screens */
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .xmas_images img {
    width: 100%;
    max-width: 300px; /* Limit the image size on mobile */
    height: auto;
    border-radius: 15px;
  }
}

@media screen and (max-width: 480px) {
  .sub-heading-xmas {
    font-size: 20px; /* Further reduce font size for very small screens */
  }

  .head-text.xmas {
    font-size: 24px; /* Smaller font size for mobile screens */
  }

  .p-text.xmas {
    font-size: 14px; /* Further reduce font size for better readability */
  }

  .xmas__btn {
    font-size: 0.75rem;
  }

  .xmas_images img {
    max-width: 250px;
  }
}
