.ai_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  justify-content: center !important;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.nft {
  max-width: 300px;
  margin: 1rem;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  border-radius: 0.7rem;
  overflow: hidden;
  transition: 0.3s all;

  .main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tokenImage {
      border-radius: 0.5rem;
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .description {
      margin-top: 0.5rem;
      color: #a89ec9;
      text-align: center;
    }
  }

  &:hover {
    box-shadow: 0 7px 20px 5px #00000088;
    transform: scale(1.05);
  }
}

.head-text {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #fff;
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #88888855;
  margin: 1rem 0;
}
