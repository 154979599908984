.network__container {
  width: 100%;
  overflow-x: hidden;
  padding: 20px;
  text-align: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      margin-bottom: 10px;
      word-wrap: break-word; /* Ensure long URLs break */
      overflow-wrap: break-word; /* Ensure long URLs break */
      a {
        text-decoration: none;
        color: blue; /* Or any other color */
        word-wrap: break-word; /* Ensure long URLs break */
        overflow-wrap: break-word; /* Ensure long URLs break */
        display: inline-block; /* Ensure it respects the container's width */
        max-width: 100%; /* Prevents content from exceeding the container */
      }
    }
  }

  .p-text {
    font-size: 16px;
    text-align: center;
  }
}

// Media queries for responsiveness

@media (min-width: 600px) {
  .network__container {
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (min-width: 900px) {
  .network__container {
    .p-text {
      font-size: 18px;
    }
  }
}

.network__container.app__flex {
  flex-direction: column;
}
.network__container-item .sub-heading-shop {
  font-size: 20px;
  font-weight: 600;
  // margin: 20px 0;

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
}

.bebirian-button {
  margin-top: 20px;
  padding: 0.75rem 1.5rem;
  background-color: var(--secondary-color);
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;

  &:hover {
    background-color: #18218b;
    color: #fff;
  }
}
