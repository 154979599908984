.videos__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.blogger__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.blogger__card {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 300px;
  text-align: center;
}

.blogger__card__image {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.video-card {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 300px;
  text-align: center;
}

.video-card__image {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.video-card__link {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  background-color: #ff0000; /* Default to YouTube Red */
}

.blogger__link {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  background-color: #ff0000;
}

.blogger__image {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.video-card__icon {
  margin-right: 8px;
}

.video-card:nth-child(3) .video-card__link {
  background-color: #1ab7ea; /* Vimeo Blue */
}

.shop-now-container {
  text-align: center;
  margin-top: 20px;
}

.shop-now-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #313bac;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
}

.shop-now-button:hover {
  background-color: #18218b;
}

.head-text.blogs {
  font-size: 32px !important;
  margin-top: 30px;
}

@media (max-width: 600px) {
  h2.head-text.videos {
    font-size: 32px !important;

    span {
      font-size: 20px;
    }
  }
  .head-text.blogs {
    font-size: 24px;
  }
}

/* Media queries for responsiveness */
@media (min-width: 600px) {
}

@media (min-width: 900px) {
  .video-card {
    width: 30%;
  }
  .blogger__card {
    width: 40%;
  }
  .sub-heading-videos {
    font-size: 22px;
  }
}
